import { useEffect, useState } from 'react';

import { useRouter } from './useRouter';

export function useActiveTab(defaultTab: string): [string, (tab: string | number) => void] {
  const { navigate, firstHash } = useRouter();
  const [activeTab, setActiveTab] = useState<string>(firstHash || defaultTab);

  useEffect(() => {
    if (activeTab) {
      navigate(`#${activeTab}`, {
        replace: true,
      });
    }
  }, [activeTab]);

  function setActiveTabWithString(tab: string | number) {
    if (typeof tab === 'number') {
      tab = tab.toString();
    }
    setActiveTab(tab);
  }

  return [activeTab, setActiveTabWithString];
}
